import React from 'react'
import PropTypes from 'prop-types'
//import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Img from 'gatsby-image'


export const BlogPostTemplate = ({
  location, coverImage, author, date, content, contentComponent, tags, title, helmet, latestPosts, galleryImages
}) => {
  const PostContent = contentComponent || Content
  const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' }

  return (
    <Layout location={location}>
      {helmet}

      <div id="breadcrumbs-container" className="container-fluid horizontal-section-container hidden-xs clearfix">
        <div className="row">
          <div className="col-xs-12">
            <ol className="breadcrumb">
              <li><Link to="/">Accueil</Link></li>
              <li><Link to="/index-blog/">Blog</Link></li>
              {/* eslint-disable-next-line */}
              <li className="active"><a href="#">{title}</a></li>
            </ol>
          </div>
        </div>
      </div>

      <div className="container-fluid horizontal-section-container clearfix">
        <div className="row">

          <div className="col-sm-9">
            <div className="section-container main-page-content clearfix">

              <div className="section-content">

                <div className="blog-articles-container clearfix">

                  <article className="blog-article clearfix">
                    <div className="blog-article-content clearfix">
                      <h1 className="title">{title}</h1>
                      <div className="meta clearfix">
                        <div className="meta-item posted-by"><span className="glyphicon glyphicon-user"></span> Par {author}</div>
                        <div className="meta-item date"><span className="glyphicon glyphicon-time"></span> {new Date(date).toLocaleDateString('fr-FR', dateOptions)}</div>
                      </div>

                      <Img fluid={coverImage.childImageSharp.fluid} style={{marginBottom: "20px"}} />

                      <div className="text-content clearfix">
                        <PostContent content={content} />
                      </div>
                    </div>
                  </article>
                </div>

                {/* Gallery */}
                <div className="gallery-grid row clearfix">
                {
                  galleryImages.map(({ node: galleryImage }) => (
                    <div className="gallery-item col-xs-8 col-sm-4" key={galleryImage.id}>
                        <a href={galleryImage.childImageSharp.fluid.src} className="img-mask-effect fade" data-lightbox="main-gallery">
                            <img src={galleryImage.childImageSharp.fluid.src} alt=" "/>
                            <i className="mask"><span className="glyphicon glyphicon-search"></span></i>
                        </a>
                    </div>
                    )
                  )
                }
                </div>

                {/* Pagination */}

              </div>
            </div>
          </div>

          <div className="col-sm-3">

            {/* Recent Posts */}
            <div id="recent-posts" className="section-container sm-extra-top-padding clearfix">

              <div className="section-header with-icon">
                <div className="icon"><span className="glyphicon glyphicon-th-list"></span></div>
                <h5 className="text">Derniers Billets</h5>
              </div>

              <div className="section-content clearfix">
                <ul className="vertical-simple-list">
                  {
                    latestPosts
                      .map(({ node: latestPost }) => (
                        <li className="item clearfix" key={latestPost.id}>
                          <div className="item-content">
                            <h6 className="title">
                              <Link to={latestPost.fields.slug}>{latestPost.frontmatter.title}</Link>
                            </h6>
                          </div>
                        </li>
                      ))
                  }
                </ul>
              </div>

            </div>

            {/* Tags */}
            {/* End: Tags */}

          </div>

        </div>
      </div>
    </Layout>
  )
}

BlogPostTemplate.propTypes = {
  author: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  //helmet: PropTypes.instanceOf(Helmet),
}

const BlogPost = ({ location, data }) => {
  const { post, latestPosts: { edges: latestPosts }, galleryImages: { edges: galleryImages }, site: { siteMetadata: { title: siteTitle } } } = data

  return (
    <BlogPostTemplate
      location={location}
      coverImage={post.frontmatter.cover_image}
      author={post.frontmatter.author}
      date={post.frontmatter.date}
      content={post.html}
      contentComponent={HTMLContent}      
      helmet={<Helmet title={`${post.frontmatter.title} | ${siteTitle}`} />}
      tags={post.frontmatter.tags}
      title={post.frontmatter.title}
      latestPosts={latestPosts}
      galleryImages={galleryImages}
    />
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!, $gallery: String!) {
    site {
      siteMetadata {
        title
      }
    },
    post: markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        author
        date
        cover_image {
          childImageSharp {
            fluid(maxWidth: 1068) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        title
        tags
      }
    },    
    latestPosts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] },
      filter: { frontmatter: { templateKey: { eq: "blog-post" } }},
      limit: 5
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
          }
        }
      }
    },
    galleryImages: allFile(
      filter: { relativeDirectory: { regex: $gallery }, extension: {ne: "md"} }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1068) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
